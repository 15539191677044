<template>
  <div class="header-info">
    <img
      class="header-bg"
      :src="bgUrl"
    />
    <div class="menu-ifno">
      <img
        class="logo"
        src="@/assets/images/logo.png"
      />
      <div class="menu">
        <div @click="goToPage('/')" class="menu-item">首页</div>
        <el-dropdown class="menu-item">
          <span class="el-dropdown-link">
            服务<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <div @click="goToPage('/service?type=kitchen')">
                厨余垃圾回收
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="goToPage('/service?type=build')">
                建筑垃圾回收
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="goToPage('/service?type=green')">
                绿化垃圾回收
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="goToPage('/service?type=big')">
                大件垃圾回收
              </div>
            </el-dropdown-item>
            <el-dropdown-item>
              <div @click="goToPage('/service?type=recyclable')">
                可回收物回收
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div @click="goToPage('/about')" class="menu-item">关于我们</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderInfo',
  data () {
    return {
      // 背景图片地址
      bgUrl: ''
    }
  },

  watch: {
    $route (newData) {
      this.getBgImgAddress(newData)
    }
  },

  methods: {
    // 跳转页面
    goToPage (path) {
      if (!path) return false
      this.$router.push({ path: path })
    },

    // 获取背景图片
    getBgImgAddress (route) {
      console.log('路由地址', route)
      const name = route.name
      let url = ''
      if (name === 'Home') { // 首页
        url = require('@/assets/images/shouye_bg.png')
      } else if (name === 'Service') { // 服务
        const type = route.query.type
        if (type === 'kitchen') { // 厨余
          url = require('@/assets/images/chuyu_bg.png')
        } else if (type === 'build') { // 建筑
          url = require('@/assets/images/jianzhu_bg.png')
        } else if (type === 'green') { // 绿化
          url = require('@/assets/images/lvhua_bg.png')
        } else if (type === 'big') { // 大件
          url = require('@/assets/images/dajian_bg.png')
        } else if (type === 'recyclable') { // 可回收物
          url = require('@/assets/images/kehuishouwu_bg.png')
        } else { // 其他则展示厨余
          url = require('@/assets/images/chuyu_bg.png')
        }
      } else { // 关于我们
        url = require('@/assets/images/about_bg.png')
      }
      this.bgUrl = url
    }
  }

}

</script>

<style lang="scss" scoped>

.header-info {
  flex: 0 0 auto;
  width: 100%;
  height: 375px;
  position: relative;
  overflow: hidden;
  .header-bg {
    width: 100%;
    height: 100%;
  }
  .menu-ifno {
    position: absolute;
    top: 20px;
    left: calc(50% - 600px);
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      flex: 0 0 auto;
      width: 111px;
      height: 54px;
    }
    .menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu-item {
        margin-left: 103px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #262E36;
        text-shadow: 0px 0px 10px #FFFFFF;
        cursor: pointer;
      }
    }
  }
}

</style>
