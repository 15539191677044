import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('@/views/service/index.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about/index.vue'),
    redirect: '/about/news',
    children: [
      {
        path: 'news',
        name: 'News',
        component: () => import('@/views/about/news/index.vue'),
      },
      {
        path: 'newsDetail',
        name: 'NewsDetail',
        component: () => import('@/views/about/newsDetail/index.vue'),
      },
      {
        path: 'my',
        name: 'My',
        component: () => import('@/views/about/my/index.vue'),
      },
      {
        path: 'about',
        name: 'AboutInfo',
        component: () => import('@/views/about/about/index.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
