<template>
  <div class="footer-info">
    <div class="footer-container">
      <div class="footer-item">
        <h3 class="footer-item-title">服务</h3>
        <div @click="goToPage('/service?type=kitchen')" class="footer-item-value">厨余垃圾回收</div>
        <div @click="goToPage('/service?type=build')" class="footer-item-value">建筑垃圾回收</div>
        <div @click="goToPage('/service?type=green')" class="footer-item-value">绿化垃圾回收</div>
        <div @click="goToPage('/service?type=big')" class="footer-item-value">大件垃圾回收</div>
        <div @click="goToPage('/service?type=recyclable')" class="footer-item-value">可回收物回收</div>
      </div>
      <div class="footer-item about">
        <h3 class="footer-item-title">关于我们</h3>
        <div @click="goToPage('/about')" class="footer-item-value">行业资讯</div>
        <div @click="goToPage('/about')" class="footer-item-value">联系我们</div>
        <div @click="goToPage('/about')" class="footer-item-value">关于群峰</div>
        <a href="https://beian.miit.gov.cn" target="_blank" class="footer-item-value">蜀ICP备19030727号</a>
        <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" class="footer-item-value">公安备案号：川公网安备51010602002045号</a>
        <div class="footer-item-value empty"></div>
        <div class="footer-item-value empty"></div>
      </div>
      <div class="footer-item code">
        <img class="qr-code" src="@/assets/images/qr_code.png" alt="">
        <div class="qr-tip">更多信息请关注微信公众号</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FooterInfo',
  data () {
    return {

    }
  },
  methods: {
    // 跳转页面
    goToPage (path) {
      if (!path) return false
      this.$router.push({ path: path })
    },
  }

}

</script>

<style lang="scss" scoped>

.footer-info {
  flex: 0 0 auto;
  width: 100%;
  height: 230px;
  background-color: #4B4F57;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-container {
    flex: 0 0 auto;
    width: 1200px;
    height: 230px;
    display: flex;
    align-items: center;
    .footer-item {
      &:first-child {
        margin-right: 260px;
      }
      display: flex;
      flex-direction: column;
      .footer-item-title {
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #FFFFFF;
      }
      .footer-item-value {
        height: 28px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        &.empty {
          cursor: default;
        }
      }
      &.about {
        flex: 1;
      }
      &.code {
        justify-content: center;
        align-items: center;
        .qr-code {
          margin-bottom: 17px;
          flex: 0 0 auto;
          width: 101px;
          height: 101px;
        }
        .qr-tip {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #FFFFFF;
        }
      }
    }
  }
}

</style>
